<h1 mat-dialog-title>
  <mat-icon
    class="dialog-header-icon"
    svgIcon="open_project"
  ></mat-icon>
  <span>{{T.F.OPEN_PROJECT.DIALOG_TRANSITION.TITLE|translate}}</span>
</h1>

<mat-dialog-content>
  <table>
    <tr>
      <th>{{T.F.OPEN_PROJECT.DIALOG_TRANSITION.TASK_NAME|translate}}</th>
      <td>{{data.task ? data.task?.title : '–'}}</td>
    </tr>
    <tr>
      <th>{{T.F.OPEN_PROJECT.DIALOG_TRANSITION.CURRENT_STATUS|translate}}</th>
      <td>
        {{data.issue?._embedded?.status?.name ? data.issue?._embedded?.status?.name :
        '–'}}
      </td>
    </tr>
    <tr>
      <th>{{T.F.OPEN_PROJECT.DIALOG_TRANSITION.CURRENT_ASSIGNEE|translate}}</th>
      <td>
        {{data.issue?._embedded?.assignee?.name ? data.issue?._embedded?.assignee?.name :
        '–'}}
      </td>
    </tr>
    <tr>
      <th>{{T.F.OPEN_PROJECT.DIALOG_TRANSITION.PERCENTAGE_DONE|translate}}</th>
      <td>
        <mat-slider
          thumbLabel
          [(ngModel)]="percentageDone"
          [displayWith]="displayThumbWith"
          tickInterval="5"
          step="5"
          min="0"
          max="100"
          aria-label="units"
        ></mat-slider>
      </td>
    </tr>
  </table>
  <mat-form-field>
    <label>{{T.F.OPEN_PROJECT.DIALOG_TRANSITION.CHOOSE_STATUS|translate}}</label>
    <mat-select [(ngModel)]="chosenTransition">
      <!--<mat-option value="DO_NOT">Don't transition</mat-option>-->
      <!--<mat-option value="ALWAYS_ASK">Always open dialog</mat-option>-->
      <mat-option
        *ngFor="let transition of (availableTransitions$|async); trackBy: trackByIndex"
        [value]="transition"
      >
        <!--<img [src]="transition.to.iconUrl">-->
        {{transition.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="wrap-buttons">
    <button
      (click)="close()"
      class="btn btn-primary submit-button"
      color="primary"
      mat-button
      type="button"
    >
      {{T.G.CANCEL|translate}}
    </button>
    <button
      (click)="transitionIssue()"
      class="btn btn-primary submit-button"
      color="primary"
      mat-stroked-button
    >
      {{T.F.OPEN_PROJECT.DIALOG_TRANSITION.TITLE|translate}}
    </button>
  </div>
</mat-dialog-actions>
