<mat-slide-toggle
  (ngModelChange)="toggleEnabled($event)"
  [ngModel]="cfg.isEnabled"
  name="isEnabled"
  style="margin-bottom: 8px"
>
  {{T.G.ENABLED|translate}}
</mat-slide-toggle>

<form
  (ngSubmit)="submit()"
  [formGroup]="form"
>
  <div *ngIf="cfg.isEnabled">
    <formly-form
      (modelChange)="cfg=$event"
      [fields]="section?.items"
      [form]="form"
      [model]="cfg"
      [options]="options"
    >
    </formly-form>

    <h3 class="sub-section-heading">{{T.F.OPEN_PROJECT.CFG_CMP.TRANSITION|translate}}</h3>
    <mat-slide-toggle
      [(ngModel)]="cfg.isTransitionIssuesEnabled"
      [ngModelOptions]="{standalone: true}"
      name="isTransitionIssuesEnabled"
      style="margin-bottom: 8px"
    >
      {{T.F.OPEN_PROJECT.CFG_CMP.ENABLE_TRANSITIONS|translate}}
    </mat-slide-toggle>

    <section
      *ngIf="cfg.isTransitionIssuesEnabled"
      @expand
    >
      <mat-form-field>
        <mat-label>
          {{T.F.OPEN_PROJECT.CFG_CMP.SELECT_ISSUE_FOR_TRANSITIONS|translate}}
        </mat-label>
        <input
          [formControl]="issueSuggestionsCtrl"
          [matAutocomplete]="autoEl"
          class="border-color-primary"
          matInput
        />

        <mat-autocomplete
          #autoEl="matAutocomplete"
          (optionSelected)="updateTransitionOptions()"
          [autoActiveFirstOption]="true"
          [displayWith]="displayIssueWith"
        >
          <mat-option
            *ngIf="(isLoading$|async)"
            [value]="issueSuggestionsCtrl.value"
            class="is-loading"
          >
            <mat-spinner diameter="50"></mat-spinner>
          </mat-option>
          <mat-option
            *ngFor="let issue of (filteredIssueSuggestions$|async); trackBy:trackByIssueId;"
            [value]="issue"
            ><span>{{issue?.title}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div *ngFor="let opt of transitionConfigOpts">
        <mat-form-field>
          <label *ngIf="opt.key==='OPEN'">
            {{T.F.OPEN_PROJECT.CFG_CMP.OPEN|translate}}
          </label>
          <label *ngIf="opt.key==='IN_PROGRESS'">
            {{T.F.OPEN_PROJECT.CFG_CMP.IN_PROGRESS|translate}}
          </label>
          <label *ngIf="opt.key==='DONE'">
            {{T.F.OPEN_PROJECT.CFG_CMP.DONE|translate}}
          </label>

          <!-- TODO check for a better way to do this -->
          <!--          <mat-select [(ngModel)]="cfg.transitionConfig[opt.key]"-->
          <mat-select
            (ngModelChange)="setTransition(opt.key, $event)"
            [ngModelOptions]="{standalone: true}"
            [ngModel]="getTransition(opt.key)"
          >
            <mat-option value="DO_NOT">
              {{T.F.OPEN_PROJECT.CFG_CMP.DO_NOT|translate}}
            </mat-option>
            <mat-option value="ALWAYS_ASK">
              {{T.F.OPEN_PROJECT.CFG_CMP.ALWAYS_ASK|translate}}
            </mat-option>
            <mat-option
              *ngFor="let transition of cfg.availableTransitions"
              [value]="transition"
            >
              {{transition.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="showSetProgressOption(opt.key)">
          <mat-checkbox
            [(ngModel)]="this.cfg.isSetProgressOnTaskDone"
            [ngModelOptions]="{standalone: true}"
          >
            {{T.F.OPEN_PROJECT.CFG_CMP.PROGRESS_ON_SAVE|translate}}
          </mat-checkbox>
          <mat-slider
            *ngIf="this.cfg.isSetProgressOnTaskDone"
            thumbLabel
            [displayWith]="displayThumbWith"
            tickInterval="5"
            min="0"
            max="100"
            step="5"
            aria-label="units"
            [(ngModel)]="this.cfg.progressOnDone"
            [ngModelOptions]="{standalone: true}"
          ></mat-slider>
        </div>
      </div>
    </section>
  </div>

  <div class="controls">
    <button
      [disabled]="!form.valid && cfg.isEnabled"
      class="btn btn-primary submit-button"
      color="primary"
      mat-raised-button
      type="submit"
    >
      <mat-icon>save</mat-icon>
      {{T.G.SAVE|translate}}
    </button>
  </div>
</form>
