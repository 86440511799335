<ng-container>
  <div
    *ngIf="task?.issueWasUpdated"
    @expand
    style="text-align: center"
  >
    <button
      (click)="hideUpdates()"
      color="accent"
      mat-raised-button
    >
      {{T.F.GITEA.ISSUE_CONTENT.MARK_AS_CHECKED|translate}}
    </button>
  </div>

  <div class="table-wrapper">
    <table class="issue-table">
      <tr>
        <th>{{T.F.GITEA.ISSUE_CONTENT.SUMMARY|translate}}</th>
        <td>
          <a
            [href]="issue?.html_url"
            target="_blank"
            ><strong>{{issue?.title}} #{{issue?.number}}</strong></a
          >
        </td>
      </tr>
      <tr>
        <th>{{T.F.GITEA.ISSUE_CONTENT.PROJECT|translate}}</th>
        <td>{{issue?.repository.name}}</td>
      </tr>
      <tr>
        <th>{{T.F.GITEA.ISSUE_CONTENT.STATUS|translate}}</th>
        <td>{{issue?.state}}</td>
      </tr>
      <tr *ngIf="issue?.assignee?.web_url">
        <th>{{T.F.GITEA.ISSUE_CONTENT.ASSIGNEE|translate}}</th>
        <td>
          <a
            [href]="issue?.assignee?.web_url"
            target="_blank"
            >{{issue?.assignee?.username}}</a
          >
        </td>
      </tr>
      <tr *ngIf="issue?.labels?.length">
        <th>{{T.F.GITEA.ISSUE_CONTENT.LABELS|translate}}</th>
        <td>
          <mat-chip-list>
            <mat-chip
              *ngFor="let label of issue?.labels"
              [title]="label.name"
              >{{label.name}}
            </mat-chip>
          </mat-chip-list>
        </td>
      </tr>
      <tr *ngIf="issue?.body">
        <th>{{T.F.GITEA.ISSUE_CONTENT.DESCRIPTION|translate}}</th>
        <td class="issue-description">
          <div
            [data]="issue?.body"
            class="description markdown"
            markdown
          ></div>
        </td>
      </tr>
    </table>
    <div style="text-align: center">
      <a
        [href]="issue?.html_url"
        class="write-a-comment"
        color="primary"
        mat-stroked-button
        target="_blank"
      >
        <mat-icon>textsms</mat-icon>
        {{T.F.GITEA.ISSUE_CONTENT.WRITE_A_COMMENT|translate}}
      </a>
    </div>
  </div>
</ng-container>
